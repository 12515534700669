import React from "react";

interface Flight {
  OnwardFlight?: {
    FCType?: string;
  };
  ReturnFlight?: {
    FCType?: string;
  };
}

interface FareDisplayProps {
  FCType?: string;
  flight?: Flight;
}

const FareDisplay: React.FC<FareDisplayProps> = ({ FCType, flight }) => {
  const corporateFareTypes = [
    "SME FARE",
    "CORPORATE SELECT",
    "SPECIAL CRP",
    "CP|CORPORATE FARES",
    "CORPORATE VALUE CLASS",
    "CORPORATE",
    "Lower cancellation charges",
    "CORPORATE FARES",
  ];

  const checkFareType = (
    type1: string | undefined,
    type2: string | undefined
  ) =>
    corporateFareTypes.includes(type1 || "") &&
    corporateFareTypes.includes(type2 || "");

  const renderFareMessage = (type: string | undefined) => {
    switch (type) {
      case "STUDENT FARE":
        return <span>Student Fare</span>;
      case "SENIOR CITIZEN":
        return <span>Sr. Citizen Fare</span>;
      case "SUPER FARE":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            Free Addons ( Meal | Seat )
          </div>
        );
      default:
        return null;
    }
  };

  const onwardFCType = flight?.OnwardFlight?.FCType;
  const returnFCType = flight?.ReturnFlight?.FCType;

  if (onwardFCType && returnFCType) {
    if (checkFareType(onwardFCType, returnFCType)) {
      return <span>Lower cancellation charges</span>;
    }

    if (onwardFCType === "STUDENT FARE" && returnFCType === "STUDENT FARE") {
      return <span>Student Fare</span>;
    }

    if (
      onwardFCType === "SENIOR CITIZEN" &&
      returnFCType === "SENIOR CITIZEN"
    ) {
      return <span>Sr. Citizen Fare</span>;
    }

    if (onwardFCType === "SUPER FARE" && returnFCType === "SUPER FARE") {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          Free Addons ( Meal | Seat )
        </div>
      );
    }
  }

  // individual `FCType`
  if (corporateFareTypes.includes(FCType || "")) {
    return <span>Lower cancellation</span>;
  }

  return renderFareMessage(FCType);
};

export default FareDisplay;
