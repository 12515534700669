import { AppLayout } from "../../components/AppLayout";
import Footer from "../../components/Footer";
import { TalkToAgents } from "../../components/TalkToAgent";
import { AddonsSection } from "../../components/forms/flight/AddonsSection";
import { TimeRemaining } from "../../components/forms/flight/TimeRemaining";
import airPlainTilt from "../../assets/images/AirplaneTilt.png";
import infoImage from "../../assets/images/info.png";
import carretDown from "../../assets/images/CaretDown.png";
import envelopImg from "../../assets/images/EnvelopeSimple.png";
import mangifyGlass from "../../assets/images/MagnifyingGlass.svg";
import leftArrow from "../../assets/images/icons8-left-30.png";
import { useNavigate } from "react-router-dom";
import { SidebarCheckout } from "../../components/forms/flight/SidebarCheckout";
import { useEffect, useState } from "react";
import LazyImage from "../../pages/LazyImage";
import { useAddons } from "../../components/forms/flight/addonccontext";
import {
  checkChangedFare,
  flightItinerary,
  flightPayment,
  getSSRforBooking,
  getSeatLayout,
  getSmartPriceForBooking,
  getSmartPriceForToken,
  saveFlightGuest,
} from "../../api/FlightAPI";
import axios from "axios";
import moment from "moment";
import { useFlightLoader } from "../../components/forms/shared/useFlightLoader";
import AirplaneLoader from "../../components/loaders/AirplaneLoader";
import { CheckoutFormPassenger } from "./CheckoutFormPassenger";
import { toast } from "react-toastify";
import { emailRegex } from "../../utils/validation";
import { getUserProfile } from "../../api/UserProfile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { slabsList } from "../../api/AdminAPI";
import { filterSSRDetails, processMAC } from "../../utils/helpers";
import AffordabilityWidget from "../payment/AffordabilityWidget";

const initialAdultObject = {
  id: 0,
  type: "adult",
  title: "Title",
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  passportNumber: "",
  passportCountry: "",
  passportExpiry: "",
  nationality: "",
};
const initialChildObject = {
  id: 0,
  type: "child",
  title: "Title",
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  passportNumber: "",
  passportCountry: "",
  passportExpiry: "",
  nationality: "",
};
const initialInfantObject = {
  id: 0,
  type: "infant",
  title: "Title",
  firstName: "",
  lastName: "",
  dateOfBirth: "",
  passportNumber: "",
  passportCountry: "",
  passportExpiry: "",
  nationality: "",
};

export const CheckoutFlight = () => {
  const flightJourneyJSON = localStorage.getItem("flightJourney");
  const flightJourney = JSON.parse(String(flightJourneyJSON));

  const flightSearchDetailsJSON = localStorage.getItem("userFlightSearchData");
  const flightSearchDetails = JSON.parse(String(flightSearchDetailsJSON));

  const [toggleEditModal, setToggleEditModal] = useState(false);
  const [checkGst, setCheckGst] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [errorForForms, setErrorForForms] = useState(false);

  const [flightDetailsObject, setFlightDetailsObject] = useState<any>();
  const [countries, setCountries] = useState([]);

  const [markedPrice, setMarkedPrice] = useState(0);
  const [storedAddonPercent, setStoredAddonPercent] = useState(0);
  const [slabsListData, setSlabsListData] = useState<any>([]);

  const [sellingPrice, setSellingPrice] = useState(0);

  const [showModal, setShowModal] = useState(false);

  const [userData, setUserData] = useState<any>({});
  const [storeSSR, setStoreSSR] = useState<any>([]);
  const [paidSSRList, setPaidSSRList] = useState<any>([]);

  const [seatpaidSSRList, setSeatPaidSSRList] = useState<any>([]);
  const [addonDetails, setAddonDetails] = useState<any>([]);
  const [addonDetailsChild, setAddonDetailsChild] = useState<any>([]);
  const [addonDetailsInfant, setAddonDetailsInfant] = useState<any>([]);

  const [mealAddonDetailsChild, setMealAddonDetailsChild] = useState<any>([]);
  const [mealAddonDetailsInfant, setMealAddonDetailsInfant] = useState<any>([]);
  const [seatAddonDetailsChild, setSeatAddonDetailsChild] = useState<any>([]);
  const [seatAddonDetailsInfant, setSeatAddonDetailsInfant] = useState<any>([]);
  const [sportBaggageDetailsChild, setSportBaggageDetailsChild] = useState<any>(
    []
  );
  const [sportBaggageDetailsInfant, setSportBaggageDetailsInfant] =
    useState<any>([]);
  const [priorityBaggageDetailsChild, setPriorityBaggageDetailsChild] =
    useState<any>([]);
  const [priorityBaggageDetailsInfant, setPriorityBaggageDetailsInfant] =
    useState<any>([]);

  const [mealAddonDetails, setMealAddonDetails] = useState<any>([]);
  const [seatAddonDetails, setSeatAddonDetails] = useState<any>([]);
  const [sportBaggageDetails, setSportBaggageDetails] = useState<any>([]);
  const [priorityBaggageDetails, setPriorityBaggageDetails] = useState<any>([]);

  const [storeCheckDomestic, setStoreCheckDomestic] = useState<any>({});
  const [checkNameChecklistSettings, setCheckNameChecklistSettings] =
    useState<any>({});
  const [searchText, setSearchText] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [phoneValue, setPhoneValue] = useState("");
  const [phoneCodeValue, setPhoneCodeValue] = useState("91");

  const [smartPriceTUI, setSmartPriceTUI] = useState("");
  const [userValues, setUserValues] = useState<{ id: string }>({ id: "" });

  const [submitClicked, setSubmitClicked] = useState(false);
  const [soldOutCheck, setSoldOutCheck] = useState(false);

  const [guestLoginIndication, setGuestLoginIndication] = useState(false);
  const [amount, setAmount] = useState(false);
  const [index, setIndex] = useState(false);
  const [orderID, setOrderID] = useState(false);

  const [registrationNumber, setRegistrationNumber] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [emailGST, setEmailGST] = useState<string>("");
  const [mobileGST, setMobileGST] = useState<string>("");

  const navigate = useNavigate();
  const { isFlightLoading, setIsFlightLoading } = useFlightLoader();

  // new code for adddons
  const handleRegistrationNumberChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRegistrationNumber(event.target.value);
  };

  const handleCompanyNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCompanyName(event.target.value);
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailGST(event.target.value);
  };

  const handleMobileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMobileGST(event.target.value);
  };
  const {
    addonDetailsData,
    setAddonDetailsData,
    mealAddonDetailsData,
    setMealAddonDetailsData,
    seatAddonDetailsData,
    setSeatAddonDetailsData,
    finalAmount,
    setFinalAmount,
    totalValue,
    setTotalValue,
    totalAmount,
    setTotalAmount,
    totalMealValue,
    setTotalMealValue,
    totalSeatValue,
    setTotalSeatValue,
    totalSportBaggageValue,
    setTotalSportBaggageValue,
    totalPriorityBaggageValue,
    setTotalPriorityBaggageValue,
    totalSSRAmount,
    setTotalSSRAmount,
  } = useAddons();

  const extractValues = (dataArray: any[]) => {
    if (dataArray && dataArray?.length > 0) {
      const uniqueDetailsMap: Record<string, Set<number>> = {}; // Map to track unique PaxIDs for each FUID
      const latestAddonDetailsArray: any[] = [];

      for (let i = dataArray?.length - 1; i >= 0; i--) {
        const { PaxID, FUID, charge, Description, SeatNumber, Fare, ...rest } =
          dataArray[i];

        if (!uniqueDetailsMap[FUID]) {
          uniqueDetailsMap[FUID] = new Set<number>();
        }

        if (!uniqueDetailsMap[FUID].has(PaxID)) {
          uniqueDetailsMap[FUID].add(PaxID);

          const filteredRest = Object.fromEntries(
            Object.entries(rest).filter(([key, value]) => value !== undefined)
          );

          latestAddonDetailsArray.unshift({ PaxID, FUID, ...filteredRest });
        }
      }

      return latestAddonDetailsArray;
    } else {
      return [];
    }
  };

  // Sample arrays
  const DataaddonDetails = addonDetails;
  const DataaddonDetailsChild = addonDetailsChild;
  const DataaddonDetailsInfant = addonDetailsInfant;
  const DatamealAddonDetails = mealAddonDetails;
  const DatamealAddonDetailsChild = mealAddonDetailsChild;
  const DatamealAddonDetailsInfant = mealAddonDetailsInfant;
  const DataseatAddonDetails = seatAddonDetails;
  const DataseatAddonDetailsChild = seatAddonDetailsChild;
  const DataseatAddonDetailsInfant = seatAddonDetailsInfant;
  const DatasportBaggageDetails = sportBaggageDetails;
  const DatasportBaggageDetailsChild = sportBaggageDetailsChild;
  const DatasportBaggageDetailsInfant = sportBaggageDetailsInfant;
  const DatapriorityBaggageDetails = priorityBaggageDetails;
  const DatapriorityBaggageDetailsChild = priorityBaggageDetailsChild;
  const DatapriorityBaggageDetailsInfant = priorityBaggageDetailsInfant;

  // Call the extractValues function for each array
  const addonDetailsArray = extractValues(DataaddonDetails);

  const addonDetailsChildArray = extractValues(DataaddonDetailsChild);
  const addonDetailsInfantArray = extractValues(DataaddonDetailsInfant);
  const mealAddonDetailsArray = extractValues(DatamealAddonDetails);
  const mealAddonDetailsChildArray = extractValues(DatamealAddonDetailsChild);
  const mealAddonDetailsInfantArray = extractValues(DatamealAddonDetailsInfant);

  const seatAddonDetailsArray = extractValues(DataseatAddonDetails);
  const seatAddonDetailsChildArray = extractValues(DataseatAddonDetailsChild);
  const seatAddonDetailsInfantArray = extractValues(DataseatAddonDetailsInfant);

  const sportBaggageDetailsArray = extractValues(DatasportBaggageDetails);
  const sportBaggageDetailsChildArray = extractValues(
    DatasportBaggageDetailsChild
  );
  const sportBaggageDetailsInfantArray = extractValues(
    DatasportBaggageDetailsInfant
  );

  const priorityBaggageDetailsArray = extractValues(DatapriorityBaggageDetails);
  const priorityBaggageDetailsChildArray = extractValues(
    DatapriorityBaggageDetailsChild
  );
  const priorityBaggageDetailsInfantArray = extractValues(
    DatapriorityBaggageDetailsInfant
  );

  const ssrDetails = [
    ...addonDetailsArray,
    ...addonDetailsChildArray,
    ...addonDetailsInfantArray,
    ...mealAddonDetailsArray,
    ...mealAddonDetailsChildArray,
    ...mealAddonDetailsInfantArray,
    ...seatAddonDetailsArray,
    ...seatAddonDetailsChildArray,
    ...seatAddonDetailsInfantArray,
    ...sportBaggageDetailsArray,
    ...sportBaggageDetailsChildArray,
    ...sportBaggageDetailsInfantArray,
    ...priorityBaggageDetailsArray,
    ...priorityBaggageDetailsChildArray,
    ...priorityBaggageDetailsInfantArray,
  ];

  console.log("Combined ssrDetails:", ssrDetails);

  useEffect(() => {
    setIsFlightLoading(true);
    const bookingDetail = localStorage.getItem("bookingDetails");

    if (bookingDetail) {
      try {
        const parsedBookingDetail = JSON.parse(String(bookingDetail));

        const handleBooking = async () => {
          await getSmartPriceForToken({
            ClientID: "API19201",
            Source: flightSearchDetails.flightWay === "one way" ? "SF" : "LV",
            // Source: "LV",
            Options: "",
            Mode: flightSearchDetails.flightWay === "one way" ? "AS" : "SS",
            TripType: flightSearchDetails.flightWay === "one way" ? "ON" : "RT",
            Trips: [
              {
                Amount: parsedBookingDetail.fare,
                Index: parsedBookingDetail.index,
                OrderID: parsedBookingDetail.orderId,
                TUI: flightJourney?.tUI,
              },
            ],
          })
            .then((res: any) => {
              setSmartPriceTUI(res.data.TUI);
              //setting in local storage to use in other tabs for api
              localStorage.setItem("smartTUI", res.data.TUI);

              getSmartPriceForBooking({
                ClientID: "API19201",
                TUI: res.data.TUI,
              })
                .then((res) => {
                  setFlightDetailsObject(res);
                  setIsFlightLoading(false);
                })
                .catch((error) =>
                  console.log(error, "flight booking token error")
                );

              setTimeout(() => {
                checkChangedFare({
                  TUI: res.data.TUI,
                  ClientID: "API19201",
                })
                  .then((res) => {
                    setStoreCheckDomestic(res?.data?.TravellerCheckList[0]);
                    setCheckNameChecklistSettings(res?.data?.FnuLnuSettings[0]);
                  })
                  .catch((err) => console.log(err));

                getSSRforBooking({
                  PaidSSR: false,
                  ClientID: "API19201",
                  Source: "LV",
                  Trips: [
                    {
                      TUI: res.data.TUI,
                      Amount: 0,
                      OrderID: 1,
                      Index: "",
                    },
                  ],
                })
                  .then((response) => {
                    if (
                      response?.data?.Trips[0]?.Journey[0]?.Segments?.length > 0
                    ) {
                      setStoreSSR(
                        response?.data?.Trips[0]?.Journey[0]?.Segments[0]?.SSR
                      );
                    }
                  })
                  .catch((error) => console.log(error, "ssr error"));

                getSSRforBooking({
                  PaidSSR: true,
                  ClientID: "API19201",
                  Source: "LV",
                  Trips: [
                    {
                      TUI: res.data.TUI,
                      Amount: 0,
                      OrderID: 1,
                      Index: "",
                    },
                  ],
                })
                  .then((response) => {
                    if (
                      response?.data?.Trips[0]?.Journey[0]?.Segments?.length > 0
                    ) {
                      setPaidSSRList(response?.data?.Trips);
                    }
                  })
                  .catch((error) => console.log(error, "ssr error"));

                getSeatLayout({
                  ClientID: "API19201",
                  Source: "LV",
                  Trips: [
                    {
                      TUI: res.data.TUI,
                      OrderID: 1,
                      Index: "",
                    },
                  ],
                })
                  .then((res) => {
                    if (res?.data?.Trips[0]?.Journey[0]?.Segments?.length > 0) {
                      setSeatPaidSSRList(res?.data?.Trips);
                    }
                    //  "--- get seat laoyout res seat"
                  })
                  .catch((err) => console.log(err));
              }, 3000);
            })
            .catch((error) => console.log(error, "flight booking token error"));
        };

        handleBooking();
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updatedChildObject = { ...initialChildObject, type: "child" };
  const updatedInfantObject = { ...initialInfantObject, type: "infant" };

  const [adultsArray, setAdultsArray] = useState([initialAdultObject]);
  const [childrensArray, setChildrensArray] = useState([updatedChildObject]);
  const [infantsArray, setInfantsArray] = useState([updatedInfantObject]);

  const combineTravelerArr = [
    ...adultsArray,
    ...childrensArray,
    ...infantsArray,
  ];

  useEffect(() => {
    const getResponseCountryCode = () => {
      axios
        .get("https://www.makemytrip.com/api/countryList")
        .then((response) => {
          setCountries(response.data);
        });
    };
    getResponseCountryCode();
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowModal(true);
    }, 15 * 60 * 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    const getUserFlightDataJSON = localStorage.getItem("userFlightSearchData");
    const parsedDataUser = JSON.parse(String(getUserFlightDataJSON));
    setUserData(parsedDataUser);
  }, []);

  useEffect(() => {
    const newAdultsArray = Array.from({ length: userData?.ADT }, () => ({
      ...initialAdultObject,
      id: Math.random(),
    }));
    setAdultsArray(newAdultsArray);

    const newChildrensArray = Array.from({ length: userData?.CHD }, () => ({
      ...initialAdultObject,
      id: Math.random(),
      type: "child",
      title: "Title",
    }));
    setChildrensArray(newChildrensArray);

    const newInfantsArray = Array.from({ length: userData?.INF }, () => ({
      ...initialAdultObject,
      id: Math.random(),
      type: "infant",
      title: "Title",
    }));
    setInfantsArray(newInfantsArray);
  }, [userData.ADT, userData.CHD, userData.INF]);

  const checkConnectingFlights =
    flightDetailsObject?.data?.Trips?.[0]?.Journey?.[0]?.Segments;

  if (checkConnectingFlights !== undefined) {
    console.log(
      flightDetailsObject,
      flightDetailsObject?.data?.Trips?.[0]?.Journey?.[0]?.Segments,
      "--obj"
    );
    localStorage.setItem("FlightName", JSON.stringify(checkConnectingFlights));
  } else {
    console.log("undefined");
  }

  useEffect(() => {
    getUserProfile()
      .then((response) => {
        setUserValues({ id: response.data._id });
      })
      .catch((err) => {
        console.log("profile error", err);
      });
  }, []);

  useEffect(() => {
    slabsList()
      .then((res) => {
        setSlabsListData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const findSlab = (netFare: number) => {
    return slabsListData?.flightSlabs?.find(
      (slab: { minAmount: number; maxAmount: number }) =>
        netFare >= slab.minAmount && netFare <= slab.maxAmount
    );
  };

  useEffect(() => {
    const flight = flightDetailsObject?.data;

    if (flight) {
      const netFare = flight.NetAmount + +finalAmount;
      const slab = findSlab(netFare);

      const markedPriceRate =
        flight.NetAmount + flight.NetAmount * (slab?.addonRate / 100);
      const sellingPriceRate =
        flight.NetAmount + flight.NetAmount * (slab?.commissionRate / 100);

      if (slab) {
        flight.CommissionRate = slab?.commissionRate;
        flight.AddonRate = slab?.addonRate;
        flight.MarkedPrice = Math.round(markedPriceRate);
        flight.SellingPrice = Math.round(sellingPriceRate + 1);

        flight.Discount = Math.round(
          ((flight.MarkedPrice - flight.SellingPrice) / flight.MarkedPrice) *
            100
        );
        flight.DiscountAmount = Math.round(
          flight.MarkedPrice - flight.SellingPrice
        );
      }

      if (flight?.SellingPrice || flight?.MarkedPrice) {
        setMarkedPrice(flight?.MarkedPrice);
        setSellingPrice(flight?.SellingPrice);
        setStoredAddonPercent(flight.NetAmount * (flight?.AddonRate / 100));
      } else {
        setMarkedPrice(0);
        setSellingPrice(0);
        setStoredAddonPercent(0);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flightDetailsObject]);

  const checkTitle = (obj: any) => {
    return obj.title === "Title";
  };

  const onConfirmHandler = () => {
    const hasEmptyValues = (obj: any) => {
      for (const key in obj) {
        if (
          storeCheckDomestic?.PassportNo !== 1 &&
          (key === "passportNumber" ||
            key === "passportCountry" ||
            key === "passportExpiry")
        ) {
          continue;
        }

        if (storeCheckDomestic?.DOB !== 1) {
          continue;
        }

        if (storeCheckDomestic?.Nationality !== 1 && key === "nationality") {
          continue;
        }

        if (obj[key] === "") {
          return true;
        }
      }
      return false;
    };

    if (
      adultsArray.some(hasEmptyValues) ||
      childrensArray.some(hasEmptyValues) ||
      infantsArray.some(hasEmptyValues) ||
      emailValue === "" ||
      phoneValue === ""
    ) {
      emailValue === "" && setEmailError(true);
      phoneValue === "" && setMobileError(true);
      toast.error("Please Fill All required User Information", {
        toastId: "errorToastRegister2",
      });
    } else if (
      adultsArray.some(checkTitle) ||
      childrensArray.some(checkTitle) ||
      infantsArray.some(checkTitle)
    ) {
      toast.error("Please Enter Valid Title", {
        toastId: "errorToastRegister3",
      });
    } else {
      !errorForForms && setToggleEditModal(true);
      setMobileError(false);
      setEmailError(false);
    }
    setSubmitClicked(true);
  };

  const getConnectingObj =
    checkConnectingFlights && checkConnectingFlights[0]?.Flight;

  const getConnectingObjSecond =
    checkConnectingFlights && checkConnectingFlights[1]?.Flight;

  function calculateTimeDifference(startTime: any, endTime: any) {
    const startDate = new Date(startTime);
    const endDate = new Date(endTime);

    const timeDifferenceInMilliseconds = Math.abs(
      endDate.getTime() - startDate.getTime()
    );
    const hours = Math.floor(timeDifferenceInMilliseconds / (1000 * 60 * 60));
    const minutes = Math.floor(
      (timeDifferenceInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
    );

    return `${String(hours).padStart(2, "0")}h ${String(minutes).padStart(
      2,
      "0"
    )}min`;
  }

  //Updating states

  const updateAdultAtIndex = (
    index: number,
    newData: {
      id: number;
      type: string;
      title: string;
      firstName: string;
      lastName: string;
      dateOfBirth: string;
      passportNumber: string;
      passportCountry: string;
      passportExpiry: string;
      nationality: string;
    }
  ) => {
    setAdultsArray((prevAdultsArray) => {
      // Create a new array with the updated data
      const updatedArray = [...prevAdultsArray];
      updatedArray[index] = newData;
      return updatedArray;
    });
  };

  const updateChildrenAtIndex = (
    index: number,
    newData: {
      id: number;
      type: string;
      title: string;
      firstName: string;
      lastName: string;
      dateOfBirth: string;
      passportNumber: string;
      passportCountry: string;
      passportExpiry: string;
      nationality: string;
    }
  ) => {
    setChildrensArray((prevChildrensArray) => {
      // Create a new array with the updated data
      const updatedArray = [...prevChildrensArray];
      updatedArray[index] = newData;
      return updatedArray;
    });
  };

  const updateInfantsAtIndex = (
    index: number,
    newData: {
      id: number;
      type: string;
      title: string;
      firstName: string;
      lastName: string;
      dateOfBirth: string;
      passportNumber: string;
      passportCountry: string;
      passportExpiry: string;
      nationality: string;
    }
  ) => {
    setInfantsArray((prevInfantsArray) => {
      // Create a new array with the updated dataaddonDetailsInfant
      const updatedArray = [...prevInfantsArray];
      updatedArray[index] = newData;
      return updatedArray;
    });
  };

  function calculateAge(birthdate: string, travelDate: string) {
    const birthDate = new Date(birthdate);
    const travelDateObj = new Date(travelDate);

    const ageDiff = travelDateObj.getFullYear() - birthDate.getFullYear();

    if (
      travelDateObj.getMonth() < birthDate.getMonth() ||
      (travelDateObj.getMonth() === birthDate.getMonth() &&
        travelDateObj.getDate() < birthDate.getDate())
    ) {
      return ageDiff - 1;
    }

    return ageDiff;
  }

  const combinedArr = combineTravelerArr.map((info, index) => ({
    ID: Number(index + 1),
    PaxID: "",
    Operation: "0",
    Title: info.title,
    FName: info.firstName.toUpperCase(),
    LName: info.lastName.toUpperCase(),
    Email: "",
    PMobileNo: "",
    Age:
      info.dateOfBirth === ""
        ? ""
        : calculateAge(
            moment(info.dateOfBirth).format("YYYY-MM-DD"),
            flightSearchDetails.OnwardDate
          ),
    DOB:
      info.dateOfBirth === ""
        ? ""
        : moment(info.dateOfBirth).format("YYYY-MM-DD"),
    Country: "",
    Gender: info.title === "Mr" ? "M" : info.title === "Mstr" ? "M" : "F",
    PTC: info.type === "adult" ? "ADT" : info.type === "child" ? "CHD" : "INF",
    Nationality: info.nationality ? info.nationality : "",
    PassportNo: info.passportNumber,
    PancardNo: "",
    PLI: info.passportCountry ? info.passportCountry : "",
    PDOI: "",
    PDOE: info.passportExpiry ? info.passportExpiry : "",
    VisaType: "VISITING VISA",
    EmigrationCheck: false,
    isOptionSelected: false,
    ApproverManagers: {
      Managers: [],
      Type: "",
    },
    DocumentType: "",
  }));

  const filteredSSR = filterSSRDetails(ssrDetails);

  const submitItinerary = () => {
    const firstAdultsArrValue = adultsArray[0];

    flightItinerary({
      TUI: smartPriceTUI,
      ServiceEnquiry: "",
      ContactInfo: {
        Title: firstAdultsArrValue.title,
        FName: firstAdultsArrValue.firstName.toUpperCase(),
        LName: firstAdultsArrValue.lastName.toUpperCase(),
        Mobile: phoneValue,
        DestMob: phoneValue,
        Phone: "",
        Email: emailValue,
        Language: "",
        Address: "MRRA 4  EDAPPALLY  Edappally , EDAPPALLY , Edappally",
        City: "Cochin",
        CountryCode: "IN",
        MobileCountryCode: `+${phoneCodeValue}`,
        DestMobCountryCode: `+${phoneCodeValue}`,
        State: "Kerala",
        PIN: "",
        GSTCompanyName: companyName,
        GSTTIN: registrationNumber,
        GstMobile: mobileGST,
        GSTEmail: emailGST,
        UpdateProfile: false,
        IsGuest: false,
        SaveGST: false,
      },
      DestinationContactInfo: {
        Address1: "",
        Address2: "",
        City: "",
        Mobile: "",
        Phone: "",
        Email: "",
        CountryCode: "",
        MobileCountryCode: `+${phoneCodeValue}`,
        State: "",
        PIN: "",
      },
      Travellers: combinedArr,
      PLP: [],
      SSR: filteredSSR,
      CrossSell: [],
      CrossSellAmount: 0,
      EnableFareMasking: false,
      SSRAmount: +totalSSRAmount,
      ClientID: "API19201",
      DeviceID: "",
      AppVersion: "",
      AgentTourCode: "",
      NetAmount: checkConnectingFlights[0]?.Fares.NetFare,
      BRulesAccepted: "",
    })
      .then((res) => {
        setIsFlightLoading(true);
        localStorage.setItem(
          "createITernTUI",
          JSON.stringify({
            TUI: res.data.TUI,
            transactionId: res.data.TransactionID,
          })
        );

        if (userValues.id === "") {
          console.log("userValues.id ", userValues.id);
          saveFlightGuest({
            email: emailValue,
            mobileNumber: phoneValue,
            countryCode: phoneCodeValue,
          })
            .then((response) => {
              setUserValues({ id: response.data._id });

              flightPayment({
                userId: response.data._id,
                txnId: String(res.data.TransactionID),
                amount: (
                  (sellingPrice && sellingPrice !== 0
                    ? Number(sellingPrice)
                    : Number(res.data.NetAmount)) + Number(totalSSRAmount)
                ).toFixed(2),
                productInfo: "flight",
                firstName: firstAdultsArrValue.firstName,
                lastName: firstAdultsArrValue.lastName,
                email: emailValue,
                phone: phoneValue,
                address1: "",
                address2: "",
                city: "",
                state: "",
                country: "",
                zipcode: "",
                Pg: "",
                udf5: "Maashree",
              })
                .then((responses) => {
                  const paymentObjString = localStorage.getItem("paymentObj");
                  let paymentObj;

                  if (!paymentObjString) {
                    paymentObj = {};
                  } else {
                    paymentObj = JSON.parse(paymentObjString);
                  }

                  paymentObj.userId = response.data._id;
                  localStorage.setItem(
                    "paymentObj",
                    JSON.stringify(paymentObj)
                  );
                  setIsFlightLoading(false);
                  window.open(responses.data, "_self");
                })
                .catch((err) => console.log(err));
            })
            .catch((err) => console.log(err));
        }

        if (userValues.id !== "") {
          flightPayment({
            userId: String(userValues?.id),
            txnId: String(res.data.TransactionID),
            amount: (
              (sellingPrice && sellingPrice !== 0
                ? Number(sellingPrice)
                : Number(res.data.NetAmount)) + Number(totalSSRAmount)
            ).toFixed(2),
            productInfo: "flight",
            firstName: firstAdultsArrValue.firstName,
            lastName: firstAdultsArrValue.lastName,
            email: emailValue,
            phone: phoneValue,
            address1: "",
            address2: "",
            city: "",
            state: "",
            country: "",
            zipcode: "",
            Pg: "",
            udf5: "Maashree",
          })
            .then((responses) => {
              const paymentObjString = localStorage.getItem("paymentObj");
              let paymentObj;

              if (!paymentObjString) {
                paymentObj = {};
              } else {
                paymentObj = JSON.parse(paymentObjString);
              }

              paymentObj.userId = String(userValues?.id);
              localStorage.setItem("paymentObj", JSON.stringify(paymentObj));
              setIsFlightLoading(false);
              window.open(responses.data, "_self");
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => {
        if (
          err.response.data.message ===
          "The requested class of service is sold out., Provider:6E"
        ) {
          setSoldOutCheck(true);
        } else {
          toast.error(err.response.data.message, {
            toastId: "confirmErrorFlight",
          });
        }
      });
  };

  const flightNotices = flightDetailsObject?.data?.Trips?.[0]?.Journey?.[0];

  // new added
  const [isClassAdded, setIsClassAdded] = useState(false);

  const handleClick = () => {
    setIsClassAdded(true);
  };

  const handleHide = () => {
    setIsClassAdded(false);
  };

  const newCalculateTotalDuration = (segments: any[]) => {
    let totalDuration = 0;
    let layoverDurations: string[] = [];

    segments.forEach(({ Flight }, index) => {
      if (Flight) {
        if (index > 0) {
          const layover = moment(Flight.DepartureTime).diff(
            moment(segments[index - 1].Flight.ArrivalTime)
          );
          totalDuration += layover;
          // layoverDurations.push(TimecalculateTimeDifference(segments[index - 1].Flight.ArrivalTime, Flight.DepartureTime));
          const individualDuration = TimecalculateTimeDifference(
            segments[index - 1].Flight.ArrivalTime,
            Flight.DepartureTime
          );
          layoverDurations.push(individualDuration);
          console.log(`Layover ${index}: ${layoverDurations}`);
        }
      }
    });
    console.log("Total Layover Duration in milliseconds: ", totalDuration);
    console.log("Individual Layover Durations: ", layoverDurations);
    let resultString = layoverDurations.join(" ");
    console.log("All Layover Durations: ", resultString);
    return resultString;
  };

  const TimecalculateTimeDifference = (
    startTime: string,
    endTime: string
  ): string => {
    const startMoment = moment(startTime);
    const endMoment = moment(endTime);
    const duration = moment.duration(endMoment.diff(startMoment));
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes()) % 60;

    return `${hours}h ${minutes}m`;
  };

  return (
    <AppLayout>
      <main className="container">
        <div className={`tabbing-bar ${isClassAdded ? "parent-active" : ""}`}>
          <div className="tab-content" id="nav-tabContent">
            <div className="row">
              <div className="col-sm-12">
                <div className="header_section">
                  <h3 className="h3-bold gap-2">
                    {flightDetailsObject?.data?.FromName?.split("|")[1]} to{" "}
                    {flightDetailsObject?.data?.ToName?.split("|")[1]}
                  </h3>
                  <span className="">
                    <LazyImage
                      className="img-fluid"
                      src={airPlainTilt}
                      alt=""
                    />
                    {moment(flightDetailsObject?.data.OnwardDate).format(
                      "DD MMM YYYY"
                    )}
                  </span>
                  <span className="">
                    {checkConnectingFlights?.length - 1} Stop
                  </span>
                </div>
                <div className="header_section">
                  <h3 className="h3-bold gap-2">
                    <span>
                      <LazyImage
                        className="img-fluid"
                        src={`https://dhiz4uvf5rpaq.cloudfront.net/images/airline-logos/${getConnectingObj?.MAC}.jpg`}
                        alt="-"
                      />
                    </span>
                    {getConnectingObj?.Airline?.split("|")[0]}
                  </h3>
                  <span className="" id="data">
                    {" "}
                    {processMAC(getConnectingObj?.MAC)} {"-"}{" "}
                    {getConnectingObj?.FlightNo}
                  </span>
                  <span style={{ textTransform: "capitalize" }}>
                    {userData.Cabin} &gt; <b> {userData.Cabin}</b>
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-8">
                <section className="cheapest_section" id="cheapest_section">
                  {checkConnectingFlights &&
                    checkConnectingFlights?.length !== 0 &&
                    checkConnectingFlights?.map(
                      (flight: any, index: number) => (
                        <span key={`check-connecting-${index + flight.Index}`}>
                          <div className="card card_checkout">
                            <div className="card-body">
                              <div className="main_content gap-2 res-checkout">
                                <div className="card_block gap-2">
                                  <div className="flight-image-section">
                                    <LazyImage
                                      className="img-fluid"
                                      src={`https://dhiz4uvf5rpaq.cloudfront.net/images/airline-logos/${flight.Flight.MAC}.jpg`}
                                      alt="-"
                                    />
                                  </div>
                                  <div className="card-content">
                                    <h3 className="box_heading">
                                      {
                                        flight?.Flight?.DepAirportName?.split(
                                          "|"
                                        )[1]
                                      }{" "}
                                      ({flight?.Flight.DepartureCode}) to{" "}
                                      {
                                        flight?.Flight?.ArrAirportName?.split(
                                          "|"
                                        )[1]
                                      }{" "}
                                      ({flight?.Flight.ArrivalCode})
                                    </h3>
                                    <p className="flight_text text-left mb-1">
                                      {moment(
                                        flight.Flight.DepartureTime
                                      ).format("DD MMM YYYY · HH:mm")}{" "}
                                      -{" "}
                                      {flight.ReturnDate !== "" &&
                                        moment(
                                          flight.Flight.ArrivalTime
                                        ).format("DD MMM YYYY · HH:mm")}{" "}
                                    </p>
                                    <p className="flight_text text-left Economy">
                                      {flight.Flight.Duration} ·{" "}
                                      {userData.Cabin}
                                    </p>
                                  </div>
                                </div>
                                <div className="card-content res-checkout">
                                  <h3 className="box_heading mb-1">Check In</h3>{" "}
                                  {storeSSR &&
                                    storeSSR?.length !== 0 &&
                                    storeSSR?.map(
                                      (ssr: {
                                        PTC: string;
                                        Description: string;
                                      }) => (
                                        <p className="flight_text text-left mb-1">
                                          <span>
                                            {ssr.PTC === "ADT"
                                              ? "Adult"
                                              : ssr.PTC === "CHD"
                                              ? "Child"
                                              : "Infant"}
                                          </span>
                                          <span>
                                            {" "}
                                            {ssr?.Description?.split(",")[0]}
                                          </span>
                                        </p>
                                      )
                                    )}
                                </div>

                                <div className="card-content res-checkout">
                                  <h3 className="box_heading mb-1">Cabin</h3>
                                  {storeSSR &&
                                    storeSSR?.length !== 0 &&
                                    storeSSR?.map(
                                      (ssr: {
                                        PTC: string;
                                        Description: string;
                                      }) => (
                                        <p className="flight_text text-left mb-1">
                                          <span>
                                            {ssr.PTC === "ADT"
                                              ? "Adult"
                                              : ssr.PTC === "CHD"
                                              ? "Child"
                                              : "Infant"}
                                          </span>
                                          <span>
                                            {" "}
                                            {ssr?.Description?.split(",")[1]}
                                          </span>
                                        </p>
                                      )
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                          {index !== checkConnectingFlights?.length - 1 && (
                            <>
                              <div
                                className="py-3 mb-2"
                                key={`connecting-${index}`}
                              >
                                <div className="card-content">
                                  <h3 className="h3-bold">Change of planes</h3>
                                  <p className="card-text mb-0">
                                    <b>
                                      {" "}
                                      {calculateTimeDifference(
                                        //   flight.Flight.DepartureTime,
                                        flight.Flight.ArrivalTime,
                                        checkConnectingFlights[index + 1].Flight
                                          .DepartureTime
                                      )}
                                      {/* {newCalculateTotalDuration(storeSegments[0])} */}{" "}
                                    </b>{" "}
                                    Layover in{" "}
                                    {
                                      flight?.Flight?.ArrAirportName?.split(
                                        "|"
                                      )[0]
                                    }{" "}
                                  </p>
                                </div>
                              </div>
                              <div className="header_section">
                                <h3 className="h3-bold gap-2">
                                  <span>
                                    <LazyImage
                                      className="img-fluid"
                                      src={`https://dhiz4uvf5rpaq.cloudfront.net/images/airline-logos/${flight?.Flight?.MAC}.jpg`}
                                      alt="-"
                                    />
                                  </span>
                                  {flight?.Flight?.Airline?.split("|")[1]}
                                </h3>
                                <span className="">
                                  {processMAC(flight?.Flight?.MAC)} {"-"}{" "}
                                  {getConnectingObjSecond?.FlightNo}
                                </span>
                                <span style={{ textTransform: "capitalize" }}>
                                  {userData.Cabin} &gt; <b> {userData.Cabin}</b>
                                </span>
                              </div>
                            </>
                          )}
                        </span>
                      )
                    )}

                  {flightNotices?.Notices === null ? (
                    <div className="content-body">
                      <div className="block">
                        <div className="content_body">
                          <LazyImage
                            className="img-fluid"
                            src={infoImage}
                            alt=""
                          />
                          <p className="text-left">NA</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="content-body">
                      <div className="block">
                        {/* <div className="content_body">
                          <LazyImage
                            className="img-fluid"
                            src={importantTag}
                            alt=""
                          />
                          <p className="text-important">
                            1 piece Check-in baggage is equivalent to 23 Kg for
                            adult/child
                          </p>
                        </div> */}
                        {flightNotices?.Notices?.map((notice: any) => (
                          <div className="content_body">
                            <LazyImage
                              className="img-fluid"
                              src={infoImage}
                              alt=""
                            />
                            <p className="text-left">{notice.Notice}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}

                  {/* Adult Information  */}

                  <div className="card card_checkout">
                    <div className="card-body">
                      {/* <h3 className="h3-bold mb-3">Adult X {userData.ADT}</h3> */}

                      {adultsArray.map((adult: any, index: number) => (
                        <CheckoutFormPassenger
                          key={`adult-${index + adult.id}`}
                          countries={countries as any}
                          index={index}
                          numberOfPassengers={adultsArray}
                          passenger={adult}
                          update={updateAdultAtIndex}
                          setErrorForForms={setErrorForForms}
                          errorForForms={submitClicked}
                          type="adult"
                          userData={userData.OnwardDate}
                          storeCheckDomestic={storeCheckDomestic}
                          checkNameChecklistSettings={
                            checkNameChecklistSettings
                          }
                        />
                      ))}
                    </div>
                    <div
                      className="airline_text"
                      style={{
                        backgroundColor: "white",
                        border: "1px solid #ccc",
                        fontWeight: "400",
                        fontSize: "13px",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faCircleExclamation}
                        style={{
                          color: "#f08000",
                          marginRight: "5px",
                          marginLeft: "5px",
                        }}
                      />
                      These details will be passed to the Airline for booking
                    </div>
                  </div>

                  {/* Child section  ---------------------*/}

                  {userData.CHD > 0 && (
                    <>
                      <div className="card card_checkout">
                        <div className="card-body">
                          {/* <h3 className="h3-bold mb-0" id="child_1">
                            Child X {userData.CHD}
                          </h3> */}
                          {childrensArray.map((child: any, index: number) => (
                            <CheckoutFormPassenger
                              key={`child-${index + child.id}`}
                              countries={countries as any}
                              index={index}
                              numberOfPassengers={childrensArray}
                              passenger={child}
                              update={updateChildrenAtIndex}
                              errorForForms={submitClicked}
                              setErrorForForms={setErrorForForms}
                              type="child"
                              userData={userData.OnwardDate}
                              storeCheckDomestic={storeCheckDomestic}
                              checkNameChecklistSettings={
                                checkNameChecklistSettings
                              }
                            />
                          ))}
                        </div>
                        <div
                          className="airline_text"
                          style={{
                            backgroundColor: "white",
                            border: "1px solid #ccc",
                            fontWeight: "400",
                            fontSize: "13px",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faCircleExclamation}
                            style={{
                              color: "#f08000",
                              marginRight: "5px",
                              marginLeft: "5px",
                            }}
                          />
                          These details will be passed to the Airline for
                          booking
                        </div>
                      </div>
                    </>
                  )}

                  {/* Infant section -------------- */}

                  {userData.INF > 0 && (
                    <>
                      <div className="card card_checkout">
                        <div className="card-body">
                          {/* <h3 className="h3-bold mb-0" id="infant_1">
                            Infant X {userData.INF}
                          </h3> */}
                          {infantsArray.map((inf: any, index: any) => (
                            <CheckoutFormPassenger
                              key={`infant-${index + inf.id}`}
                              countries={countries as any}
                              index={index}
                              numberOfPassengers={infantsArray}
                              passenger={inf}
                              update={updateInfantsAtIndex}
                              errorForForms={submitClicked}
                              setErrorForForms={setErrorForForms}
                              type="infant"
                              userData={userData.OnwardDate}
                              storeCheckDomestic={storeCheckDomestic}
                              checkNameChecklistSettings={
                                checkNameChecklistSettings
                              }
                            />
                          ))}
                        </div>
                        <div
                          className="airline_text"
                          style={{
                            backgroundColor: "white",
                            border: "1px solid #ccc",
                            fontWeight: "400",
                            fontSize: "13px",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faCircleExclamation}
                            style={{
                              color: "#f08000",
                              marginRight: "5px",
                              marginLeft: "5px",
                            }}
                          />
                          These details will be passed to the Airline for
                          booking
                        </div>
                      </div>
                    </>
                  )}

                  {/* addons */}
                  <AddonsSection
                    paidSSRList={paidSSRList}
                    seatpaidSSRList={seatpaidSSRList}
                    adultsArray={adultsArray}
                    childrenArray={childrensArray}
                    infantArray={infantsArray}
                    userData={userData}
                    setAddonDetails={setAddonDetails}
                    addonDetails={addonDetails}
                    setMealAddonDetails={setMealAddonDetails}
                    mealAddonDetails={mealAddonDetails}
                    setSeatAddonDetails={setSeatAddonDetails}
                    seatAddonDetails={seatAddonDetails}
                    setSportBaggageDetails={setSportBaggageDetails}
                    sportBaggageDetails={sportBaggageDetails}
                    priorityBaggageDetails={priorityBaggageDetails}
                    setPriorityBaggageDetails={setPriorityBaggageDetails}
                    // seatAddonDetails={seatAddonDetails}
                    onClick={handleClick}
                    onHide={handleHide}
                    checkConnectingFlights={checkConnectingFlights}
                    setAddonDetailsChild={setAddonDetailsChild}
                    addonDetailsChild={addonDetailsChild}
                    setAddonDetailsInfant={setAddonDetailsInfant}
                    addonDetailsInfant={addonDetailsInfant}
                    setMealAddonDetailsChild={setMealAddonDetailsChild}
                    mealAddonDetailsChild={mealAddonDetailsChild}
                    setMealAddonDetailsInfant={setMealAddonDetailsInfant}
                    mealAddonDetailsInfant={mealAddonDetailsInfant}
                    setSeatAddonDetailsChild={setSeatAddonDetailsChild}
                    seatAddonDetailsChild={seatAddonDetailsChild}
                    setSeatAddonDetailsInfant={setSeatAddonDetailsInfant}
                    seatAddonDetailsInfant={seatAddonDetailsInfant}
                    setSportBaggageDetailsChild={setSportBaggageDetailsChild}
                    sportBaggageDetailsChild={sportBaggageDetailsChild}
                    setSportBaggageDetailsInfant={setSportBaggageDetailsInfant}
                    sportBaggageDetailsInfant={sportBaggageDetailsInfant}
                    priorityBaggageDetailsChild={priorityBaggageDetailsChild}
                    setPriorityBaggageDetailsChild={
                      setPriorityBaggageDetailsChild
                    }
                    priorityBaggageDetailsInfant={priorityBaggageDetailsInfant}
                    setPriorityBaggageDetailsInfant={
                      setPriorityBaggageDetailsInfant
                    }

                    // totalFareCalc={totalFareCalc}
                    // updateBaggageAddons={handleBooking}
                  />

                  <div style={{ padding: "15px" }} className="row">
                    <div
                      style={{
                        display: "flex",
                        gap: "7px",
                        marginBottom: "12px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id="gst-toggle"
                        style={{ height: "25px", width: "17px" }}
                        onChange={() => setCheckGst(!checkGst)}
                      />
                      <label htmlFor="gst-toggle" style={{ fontWeight: "600" }}>
                        I have a GST number (optional)
                      </label>
                    </div>
                    {checkGst === true && (
                      <div>
                        <div className="card-content">
                          <div className="mb-4 text-left1">
                            <div
                              style={{ display: "grid", padding: "5px 25px" }}
                            >
                              <label
                                htmlFor="company-number"
                                style={{ marginBottom: "5px" }}
                              >
                                Registration number
                              </label>
                              <input
                                type="text"
                                id="company-number"
                                placeholder="Registration Number"
                                style={{
                                  borderRadius: "4px",
                                  border: "1px solid #bdbdbd",
                                  fontSize: "14px",
                                  padding: "5px",
                                }}
                                onChange={handleRegistrationNumberChange}
                              />
                            </div>

                            <div
                              style={{ display: "grid", padding: "5px 25px" }}
                            >
                              <label
                                htmlFor="company-gst"
                                style={{ marginBottom: "5px" }}
                              >
                                Company Name
                              </label>
                              <input
                                type="text"
                                id="company-gst"
                                placeholder="Company Name"
                                style={{
                                  borderRadius: "4px",
                                  border: "1px solid #bdbdbd",
                                  fontSize: "14px",
                                  padding: "5px",
                                }}
                                onChange={handleCompanyNameChange}
                              />
                            </div>

                            <div
                              style={{ display: "grid", padding: "5px 25px" }}
                            >
                              <label
                                htmlFor="email-gst"
                                style={{ marginBottom: "5px" }}
                              >
                                Email
                              </label>
                              <input
                                type="text"
                                id="email-gst"
                                placeholder="Email"
                                style={{
                                  borderRadius: "4px",
                                  border: "1px solid #bdbdbd",
                                  fontSize: "14px",
                                  padding: "5px",
                                }}
                                onChange={handleEmailChange}
                              />
                            </div>

                            <div
                              style={{ display: "grid", padding: "5px 25px" }}
                            >
                              <label
                                htmlFor="full-name-gst"
                                style={{
                                  marginBottom: "5px",
                                }}
                              >
                                Mobile No.
                              </label>
                              <input
                                type="text"
                                id="mobile-gst"
                                placeholder="Mobile"
                                style={{
                                  borderRadius: "4px",
                                  border: "1px solid #bdbdbd",
                                  fontSize: "14px",
                                  padding: "5px",
                                  textTransform: "uppercase",
                                }}
                                onChange={handleMobileChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="form-group">
                    <h3 className="h3-bold">Contact Information</h3>

                    <label className="email_text">
                      <span style={{ marginRight: "5px" }}>
                        <LazyImage
                          className="img-fluid"
                          src={envelopImg}
                          alt=""
                        />
                      </span>
                      Your booking information will be sent here..
                    </label>
                  </div>
                  <div className="row pb-3">
                    <div className="col-sm-6 my-2 text-left1 mobile-listing">
                      <div className="mobile-select">
                        <div className="mobile-data-list">
                          <div className="dropdown">
                            <a
                              className="dropdown-toggle"
                              href="/"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <span className="country-code">
                                +{phoneCodeValue}
                                <LazyImage
                                  src={carretDown}
                                  className="caret"
                                  alt=""
                                />{" "}
                              </span>
                            </a>
                            <ul className="dropdown-menu">
                              <li>
                                <div className="country-code-box">
                                  <LazyImage
                                    src={mangifyGlass}
                                    className="search-icon"
                                    alt=""
                                  />
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="exampleFormControlInput1"
                                    placeholder="Country or Code"
                                    value={searchText}
                                    onChange={(e) =>
                                      setSearchText(e.target.value)
                                    }
                                  />
                                </div>
                              </li>
                              {countries
                                .filter(({ name, phoneCode, nameCode }) =>
                                  `${name} ${phoneCode} ${nameCode}`
                                    .toLowerCase()
                                    .includes(searchText.toLowerCase())
                                )
                                .map(({ name, phoneCode, nameCode }, idx) => (
                                  <li
                                    key={`phone-code-${phoneCode + idx}`}
                                    onClick={() => setPhoneCodeValue(phoneCode)}
                                  >
                                    <span className="dropdown-item">
                                      <span>
                                        {" "}
                                        {name} (+{phoneCode})
                                      </span>{" "}
                                      <span
                                        className="code-country"
                                        style={{ textTransform: "uppercase" }}
                                      >
                                        {nameCode}{" "}
                                      </span>
                                    </span>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          style={{
                            border: guestLoginIndication
                              ? "2px solid #ff7f00"
                              : "",
                          }}
                          id="exampleFormControlInput1"
                          placeholder="Add your mobile no."
                          value={phoneValue}
                          onChange={(e) => {
                            setPhoneValue(e.target.value);
                            setGuestLoginIndication(false);
                          }}
                        />
                      </div>
                      {phoneValue === "" && mobileError && (
                        <p style={{ color: "red", margin: "4px" }}>
                          {" "}
                          Please enter your Mobile no.
                        </p>
                      )}
                    </div>
                    <div className="col-sm-6 my-2">
                      <input
                        type="email"
                        style={{
                          border: guestLoginIndication
                            ? "2px solid #ff7f00"
                            : "",
                        }}
                        className="form-control"
                        placeholder="Email"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        value={emailValue}
                        onChange={(e) => {
                          setEmailValue(e.target.value);
                          const testEmail = emailRegex.test(e.target.value);
                          setErrorForForms(!testEmail);
                          setGuestLoginIndication(false);
                        }}
                      />
                      {emailValue === "" && emailError && (
                        <p style={{ color: "red", margin: "4px" }}>
                          {" "}
                          Please enter your email
                        </p>
                      )}
                      {emailValue !== "" && !emailRegex.test(emailValue) && (
                        <p style={{ color: "red", margin: "4px" }}>
                          {" "}
                          Please enter a valid email
                        </p>
                      )}
                    </div>
                  </div>
                </section>
              </div>

              {/* Sidebar checkout component*/}
              {checkConnectingFlights !== undefined && (
                <SidebarCheckout
                  key={`'checkout-sidebar-component-${Math.random()}`}
                  getObjRules={checkConnectingFlights[0]?.Fares}
                  flightSearchDetails={flightSearchDetails}
                  flightData={flightDetailsObject}
                  phoneValue={phoneValue}
                  emailValue={emailValue}
                  setGuestLoginIndication={setGuestLoginIndication}
                  sellingPrice={sellingPrice}
                  setSellingPrice={setSellingPrice}
                  markedPrice={markedPrice}
                  storedAddonPercent={storedAddonPercent}
                />
              )}

              {/* PayU widget  */}
              {checkConnectingFlights &&
                checkConnectingFlights.length !== 0 && (
                  <AffordabilityWidget
                    key="JYtYXl"
                    amount={
                      checkConnectingFlights &&
                      checkConnectingFlights.length !== 0
                        ? checkConnectingFlights[0]?.Fares.NetFare
                        : "2000"
                    }
                  />
                )}
            </div>

            <span>
              {" "}
              By clicking on <b>Continue to payment</b>, I agree with the
              Booking Policies, Privacy Policy & Terms.
            </span>
            <div
              className="inline-text payment_btn"
              style={{ marginTop: "18px" }}
            >
              <button
                className="btn btn-login"
                type="button"
                onClick={() => {
                  onConfirmHandler();
                }}
              >
                Continue to payment
              </button>
            </div>
          </div>
        </div>
      </main>
      {/* // Edit modal  start */}
      {toggleEditModal && (
        <div
          className="modal fade show"
          id="exampleModal_Edit"
          aria-labelledby="exampleModalLabel_Edit"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          style={{ display: toggleEditModal ? "block" : "none" }}
          role="dialog"
        >
          <div className="overlay"> </div>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="checkout-heading" id="exampleModalLabel_Edit">
                  {soldOutCheck ? "Tickets sold out" : "Review Details"}
                </h3>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setToggleEditModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                <div className="content-body">
                  {soldOutCheck ? (
                    <div style={{ display: "flex" }}>
                      <LazyImage
                        className="img-fluid sorry_emoji"
                        src={`https://d3c46yygxatk0.cloudfront.net/cdn/images/sorry-emoji.png`}
                        alt="flight"
                      />
                      <p
                        className="edit_lead"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          margin: "10px",
                          fontSize: "18px",
                        }}
                      >
                        Sorry, the requested seats are sold out. Please click
                        'Continue Search' to find another flight.
                      </p>
                    </div>
                  ) : (
                    <p className="edit_lead">
                      Please ensure that the spelling of your name and other
                      details match with your travel document/govt. ID, as these
                      cannot be changed later. Errors might lead to cancellation
                      penalties.
                    </p>
                  )}
                </div>
              </div>
              {!soldOutCheck && (
                <div className="card mx-2 border-0">
                  <h3 className="checkout-heading px-3">ADULT</h3>
                  {adultsArray.map((ad, index) => (
                    <div className="card-body pt-0" key={`adults-arr-${index}`}>
                      <div className="box-list">
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="edit_lead mb-1">Title</p>
                          <p
                            className="edit_lead mb-1"
                            style={{ textTransform: "uppercase" }}
                          >
                            {ad.title}
                          </p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="edit_lead mb-1">First Name</p>
                          <p
                            className="edit_lead mb-1"
                            style={{ textTransform: "uppercase" }}
                          >
                            {ad.firstName}
                          </p>
                        </div>
                      </div>
                      <div className="box-list">
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="edit_lead mb-1">Last Name</p>
                          <p
                            className="edit_lead mb-1"
                            style={{ textTransform: "uppercase" }}
                          >
                            {ad.lastName}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                  {childrensArray?.length > 0 &&
                    childrensArray?.map((ad, index) => (
                      <div
                        className="card-body pt-0"
                        key={`child-arr-${index}`}
                      >
                        <div className="box-list">
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="edit_lead mb-1">Title</p>
                            <p
                              className="edit_lead mb-1"
                              style={{ textTransform: "uppercase" }}
                            >
                              {ad.title}
                            </p>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="edit_lead mb-1">First Name</p>
                            <p
                              className="edit_lead mb-1"
                              style={{ textTransform: "uppercase" }}
                            >
                              {ad.firstName}
                            </p>
                          </div>
                        </div>
                        <div className="box-list">
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="edit_lead mb-1">Last Name</p>
                            <p
                              className="edit_lead mb-1"
                              style={{ textTransform: "uppercase" }}
                            >
                              {ad.lastName}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}

                  {infantsArray?.length > 0 &&
                    infantsArray?.map((ad, index) => (
                      <div
                        className="card-body pt-0"
                        key={`infant-arr-${index}`}
                      >
                        <div className="box-list">
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="edit_lead mb-1">Title</p>
                            <p
                              className="edit_lead mb-1"
                              style={{ textTransform: "uppercase" }}
                            >
                              {ad.title}
                            </p>
                          </div>
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="edit_lead mb-1">First Name</p>
                            <p
                              className="edit_lead mb-1"
                              style={{ textTransform: "uppercase" }}
                            >
                              {ad.firstName}
                            </p>
                          </div>
                        </div>
                        <div className="box-list">
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="edit_lead mb-1">Last Name</p>
                            <p
                              className="edit_lead mb-1"
                              style={{ textTransform: "uppercase" }}
                            >
                              {ad.lastName}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              )}

              <div className="modal-footer">
                {soldOutCheck ? (
                  <>
                    <button
                      type="button"
                      className="btn btn-login"
                      style={{ backgroundColor: "#ff5d62" }}
                      onClick={() => navigate("/")}
                    >
                      Back to Home page
                    </button>
                    <button
                      type="button"
                      className="btn btn-login"
                      onClick={() => navigate("/search-flights")}
                    >
                      Continue to search
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      type="button"
                      className="btn btn-login"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => setToggleEditModal(false)}
                    >
                      EDIT
                    </button>
                    <button
                      type="button"
                      className="btn btn-login"
                      data-bs-dismiss="modal"
                      onClick={submitItinerary}
                    >
                      Confirm
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* // Edit modal end  */}

      {/* // Flight Fare comparison modal   */}
      <div
        className="modal fade"
        id="change-to-flight-popup"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        style={{ display: "none" }}
        aria-modal="true"
        role="dialog"
      >
        <div className="overlay"> </div>
        <div className="modal-dialog modal-xl modal-dialog-centered">
          <div className="modal-content site-modal site_modal">
            <div className="modal-body">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <div className="row modal-row1">
                <div className="container px-3 py-2">
                  <div className="right-section">
                    <div className="card_block">
                      <div className="flight-image-section py-2">
                        <LazyImage
                          className="img-fluid"
                          src={`https://dhiz4uvf5rpaq.cloudfront.net/images/airline-logos/${getConnectingObj?.MAC}.jpg`}
                          alt="flight"
                        />
                      </div>
                      <div className="d-flex justify-content-center align-items-center">
                        <div className="card-content">
                          <div className="d-flex justify-content-center align-content-center m-auto py-2 gap-3">
                            <div className="first-block">
                              <h3 className="h3-bold text-center">
                                {
                                  flightDetailsObject?.data?.FromName?.split(
                                    "|"
                                  )[1]
                                }
                              </h3>
                            </div>
                            <div className="second-block">
                              <LazyImage
                                className="img-fluid"
                                src={leftArrow}
                                alt="flight"
                              />
                            </div>
                            <div className="third-block">
                              <h3 className="h3-bold text-center">
                                {" "}
                                {
                                  flightDetailsObject?.data?.ToName?.split(
                                    "|"
                                  )[1]
                                }
                              </h3>
                            </div>
                          </div>
                          <p className="flight_text text-center text-dark mb-1 px-2">
                            We regret on a change in the Fare for the Flight you
                            have selected. You may proceed with the new fare or
                            Return to the Results page and select a different
                            Flight
                          </p>
                        </div>
                      </div>
                      <hr />
                      <div className="d-flex border-1 justify-content-center align-content-center m-auto py-2 gap-3">
                        <div className="first-block">
                          <span className="text-danger text-center">
                            {" "}
                            Old Fare
                          </span>
                          <h3 className="h3-bold text-center">₹ 59924 </h3>
                        </div>
                        <div className="second-block mx-0 my-auto">
                          <LazyImage
                            className="img-fluid"
                            src={leftArrow}
                            alt="flight"
                          />
                        </div>
                        <div className="third-block">
                          <span className="text-dark text-center">
                            {" "}
                            New Fare
                          </span>
                          <h3 className="h3-bold text-center">₹ 60911</h3>
                        </div>
                      </div>
                      <hr />

                      <div className="d-flex gap-5 align-items-center justify-content-center mb-0 px-3 py-3">
                        <div className="d-flex justify-content-center align-items-center gap-3 w-100">
                          <div className="card-content">
                            <button className="btn btn-login" type="button">
                              Return{" "}
                            </button>
                          </div>
                          <div className="card-content">
                            <button className="btn btn-login" type="button">
                              Continue
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isFlightLoading && <AirplaneLoader />}
      {showModal && <TimeRemaining />}

      <TalkToAgents />
      <Footer />
    </AppLayout>
  );
};
